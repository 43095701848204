


















































import { Component, Vue, Watch } from "vue-property-decorator";
import ClientService from "@/api/clients";
import AddPersonaModal from "@/components/personas/AddPersonaModal.vue";
import { mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters("ClientModule", {
      clients: "getClients"
    }),
    ...mapGetters("UserModule", {
      storage: "getStorage"
    })
  },
  components: {
    AddPersonaModal
  }
})
export default class Productores extends Vue {
  isAddingClient = false;
  selectedClient: any = {};
  updateKey = 0;
  clients!: any[];
  storage!: any;
  columns = [
    {
      field: "id",
      label: "ID",
      width: "100",
      numeric: true,
      searchable: true
    },
    {
      field: "name",
      label: "Nombre",
      searchable: true
    },
    {
      field: "last_name",
      label: "Apellido",
      searchable: true
    },
    {
      field: "phone_number",
      label: "# de teléfono"
    }
  ];

  closeModal(): void {
    this.isAddingClient = false;
    this.updateKey++;
  }
  addClient() {
    this.isAddingClient = true;
    this.selectedClient = {};
  }

  deleteClient(clientId: number): void {
    this.$buefy.dialog.confirm({
      message: "¿Estas seguro de borrar a este cliente?",
      cancelText: "Cancelar",
      confirmText: "Si, borrar",
      type: "is-danger",
      onConfirm: () => {
        ClientService.delete(clientId)
          .then(() => {
            const index = this.clients.findIndex(x => x.id == clientId);
            this.clients.splice(index, 1);
            this.updateKey++;
            this.$buefy.notification.open({
              message: "Se ha borrado al cliente",
              type: "is-success"
            });
          })
          .catch(() => {
            this.$buefy.notification.open({
              message:
                "No se puede borrar el cliente porque tiene nueces asociadas",
              type: "is-warning"
            });
          });
      }
    });
  }

  @Watch("selectedClient")
  toggleEditClient(val: any) {
    if (Object.keys(val).length === 0 && val.constructor === Object) {
      return;
    }
    this.isAddingClient = true;
  }

  mounted() {
    ClientService.get(this.storage.id, "name,last_name,id, phone_number").then(
      response => {
        this.$store.dispatch("ClientModule/setClients", response.data);
      }
    );
  }
}
